import { useErrorBoundary } from "react-error-boundary";
import { useContext } from "react";
import { UserContext } from "../wrappers/user/UserDataWrapper";
import { del, get, post, put, ApiError } from "aws-amplify/api";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";

export default function useRestApi() {
  const { idToken } = useContext(UserContext);
  const { showBoundary } = useErrorBoundary();
  const intl = useIntl();

  const executeGetCall = async (path, options = {}) => {
    try {
      const restOperation = get({
        apiName: "YumalarmRestAPI",
        path,
        options: {
          ...options,
          headers: {
            Authorization: idToken.toString(),
            ...options.headers,
          },
        },
      });

      let { statusCode, body } = await restOperation.response;

      if (statusCode === 204) {
        return { statusCode, body: {} };
      } else {
        return { statusCode, body: await body.json() };
      }
    } catch (error) {
      return handleError(error);
    }
  };

  const executePostCall = async (path, options = {}) => {
    try {
      const restOperation = post({
        apiName: "YumalarmRestAPI",
        path,
        options: {
          ...options,
          headers: {
            Authorization: idToken.toString(),
            ...options.headers,
          },
        },
      });

      let { statusCode, body } = await restOperation.response;

      if (statusCode === 204) {
        return { statusCode, body: {} };
      } else {
        return { statusCode, body: await body.json() };
      }
    } catch (error) {
      return handleError(error);
    }
  };

  const executeDeleteCall = async (path, options = {}) => {
    try {
      const restOperation = del({
        apiName: "YumalarmRestAPI",
        path,
        options: {
          ...options,
          headers: {
            Authorization: idToken.toString(),
            ...options.headers,
          },
        },
      });

      let { statusCode, body } = await restOperation.response;

      if (statusCode === 204) {
        return { statusCode, body: {} };
      } else {
        return { statusCode, body: await body.json() };
      }
    } catch (error) {
      return handleError(error);
    }
  };

  const executePutCall = async (path, options = {}) => {
    try {
      const restOperation = put({
        apiName: "YumalarmRestAPI",
        path,
        options: {
          ...options,
          headers: {
            Authorization: idToken.toString(),
            ...options.headers,
          },
        },
      });

      let { statusCode, body } = await restOperation.response;

      if (statusCode === 204) {
        return { statusCode, body: {} };
      } else {
        return { statusCode, body: await body.json() };
      }
    } catch (error) {
      return handleError(error);
    }
  };

  function handleError(error) {
    if (error instanceof ApiError && error.response) {
      const { statusCode, body } = error.response;

      if (statusCode === 500) {
        toast.error(intl.formatMessage({ id: "error.500" }));
        return { statusCode, body: JSON.parse(body) };
      } else if (statusCode === 403) {
        toast.warn(intl.formatMessage({ id: "error.403" }));
        return { statusCode, body: JSON.parse(body) };
      } else if (statusCode === 401) {
        toast.warn(intl.formatMessage({ id: "error.401" }));
        return { statusCode, body: JSON.parse(body) };
      } else if (statusCode === 400) {
        toast.warn(intl.formatMessage({ id: "error.400" }));
        return { statusCode, body: JSON.parse(body) };
      }
    }

    showBoundary(error);
  }

  return { executeGetCall, executePostCall, executeDeleteCall, executePutCall };
}
