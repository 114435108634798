export function validateEmail(email, intl) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  let isValid = true;
  let validationErrors = {};

  if (!emailRegex.test(email)) {
    isValid = false;
    validationErrors["email"] = intl.formatMessage({
      id: "e-mail.validation",
    });
  }

  return { isValid: isValid, validationErrors: validationErrors };
}
