import { useState, useEffect, createContext } from "react";
import { IntlProvider } from "react-intl";
import Slovenian from "./sl.json";
import English from "./en.json";
import German from "./de.json";
import { LOCAL_STORAGE } from "../../constants/Constants";

export const LanguageContext = createContext();

function getMessages(locale) {
  if (locale === "sl") {
    return Slovenian;
  } else if (locale === "de") {
    return German;
  } else {
    return English;
  }
}

export default function LanguageWrapper({ children }) {
  const [locale, setLocale] = useState(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE.LANGUAGE)) || "en"
  );
  const [messages, setMessages] = useState(getMessages(locale));

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE.LANGUAGE, JSON.stringify(locale));
  }, [locale]);

  function selectLanguage(newLocale) {
    if (newLocale === "en") {
      setMessages(English);
    } else if (newLocale === "sl") {
      setMessages(Slovenian);
    } else if (newLocale === "de") {
      setMessages(German);
    }
    setLocale(newLocale);
  }

  return (
    <LanguageContext.Provider value={{ locale, selectLanguage }}>
      <IntlProvider messages={messages} locale={locale}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
}
