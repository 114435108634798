import {
  EnvelopeOpenIcon,
  PlusIcon,
  QueueListIcon,
} from "@heroicons/react/24/outline";
import { Tab } from "@headlessui/react";
import { FormattedMessage } from "react-intl";
import { joinClasses } from "../../helpers/Utils";

const features = [
  {
    nameKey: "secondary.features.organize",
    summaryKey: "secondary.features.organize.summary",
    descriptionKey: "secondary.features.organize.description",
    icon: QueueListIcon,
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50",
  },
  {
    nameKey: "secondary.features.configure",
    summaryKey: "secondary.features.configure.summary",
    descriptionKey: "secondary.features.configure.description",
    icon: EnvelopeOpenIcon,
    iconForeground: "text-yellow-700",
    iconBackground: "bg-yellow-50",
  },
  {
    nameKey: "secondary.features.generate",
    summaryKey: "secondary.features.generate.summary",
    descriptionKey: "secondary.features.generate.description",
    icon: PlusIcon,
    iconForeground: "text-teal-700",
    iconBackground: "bg-teal-50",
  },
];

function Feature({ feature, isActive, className, ...props }) {
  return (
    <div
      className={joinClasses(
        className,
        !isActive && "opacity-75 hover:opacity-100"
      )}
      {...props}
    >
      <div>
        <span
          className={joinClasses(
            isActive ? feature.iconForeground : feature.iconBackground,
            "inline-flex rounded-lg p-3 ring-4 ring-white"
          )}
        >
          <feature.icon className="h-9 w-9" aria-hidden="true" />
        </span>
      </div>
      <h3
        className={joinClasses(
          "mt-6 text-sm font-medium",
          isActive ? "text-blue-600" : "text-slate-600"
        )}
      >
        {feature.name}
      </h3>
      <p className="mt-2 font-display text-xl text-slate-900">
        <FormattedMessage id={feature.summaryKey} />
      </p>
      <p className="mt-4 text-sm text-slate-600">
        <FormattedMessage id={feature.descriptionKey} />
      </p>
    </div>
  );
}

function FeaturesMobile() {
  return (
    <div className="-mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
      {features.map((feature) => (
        <div key={feature.nameKey + "-mobile-feature"}>
          <Feature feature={feature} className="mx-auto max-w-2xl" isActive />
        </div>
      ))}
    </div>
  );
}

function FeaturesDesktop() {
  return (
    <Tab.Group as="div" className="hidden lg:mt-20 lg:block">
      {({ selectedIndex }) => (
        <>
          <Tab.List className="grid grid-cols-3 gap-x-8">
            {features.map((feature, featureIndex) => (
              <Feature
                key={feature.nameKey + "-desktop-feature"}
                feature={{
                  ...feature,
                  name: (
                    <Tab className="outline-none">
                      <span className="absolute inset-0" />
                      <FormattedMessage id={feature.nameKey} />
                    </Tab>
                  ),
                }}
                isActive={featureIndex === selectedIndex}
                className="relative"
              />
            ))}
          </Tab.List>
        </>
      )}
    </Tab.Group>
  );
}

export default function SecondaryFeatures() {
  return (
    <section
      id="secondary-features"
      aria-label="Features for simplifying everyday business tasks"
      className="pb-14 pt-20 sm:pb-20 sm:pt-32 lg:pb-32"
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl md:text-center">
          <h2 className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
            <FormattedMessage id="secondary.features.title" />
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            <FormattedMessage id="secondary.features.subtitle" />
          </p>
        </div>
        <FeaturesMobile />
        <FeaturesDesktop />
      </div>
    </section>
  );
}
