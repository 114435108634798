import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import Loading from "../../components/loading/Loading";
import TextInput from "../../components/input/text/TextInput";
import { HalfWidthPageWrapper } from "../../components/page-wrappers/PageWrappers";
import FormTitle from "../../components/form-title/FormTitle";
import useRestApi from "../../custom-hooks/useRestApi";
import { validateEmail } from "../../validation/EmailValidation";
import List from "../../components/list/List";

export default function EditCategoryPage() {
  const { categoryId } = useParams();
  const [categoryName, setCategoryName] = useState("");
  const [email, setEmail] = useState("");
  const [refreshKey, setRefreshKey] = useState(0);
  const [validationError, setValidationError] = useState("");
  const [loading, setLoading] = useState(true);
  const [blockInviteButton, setBlockInviteButton] = useState(false);
  const { executeGetCall, executePostCall, executeDeleteCall } = useRestApi();
  const intl = useIntl();

  useEffect(() => {
    async function initialLoad() {
      let categoryResponse = await executeGetCall(`/categories/${categoryId}`);
      setCategoryName(categoryResponse.body.name);
      setLoading(false);
    }

    initialLoad();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  async function handleFetchItems(nextToken) {
    let queryParams = { limit: 10, categoryId };
    if (nextToken) {
      queryParams.token = JSON.stringify(nextToken);
    }

    let { body } = await executeGetCall("permissions", { queryParams });

    return {
      items: body.items,
      token: body.nextToken ? body.nextToken : null,
    };
  }

  async function handleInviteClick() {
    setBlockInviteButton(true);
    const validationResult = validateEmail(email, intl);

    if (!validationResult.isValid) {
      setValidationError(validationResult.validationErrors["email"]);
      setBlockInviteButton(false);
      return;
    }

    let { statusCode, body } = await executePostCall(`permissions`, {
      body: {
        categoryId,
        email,
      },
    });

    if (statusCode === 200) {
      setEmail("");
      setRefreshKey((prevKey) => prevKey + 1);
    } else if (statusCode === 400) {
      if (body.errorType === "UserNotFoundException") {
        setValidationError(
          intl.formatMessage({
            id: "category.share.email.not.found",
          })
        );
      } else if (body.errorType === "CategoryAlreadySharedException") {
        setValidationError(
          intl.formatMessage({
            id: "category.share.already.shared",
          })
        );
      }
    }

    setBlockInviteButton(false);
  }

  async function removeInvite(invite) {
    await executeDeleteCall(`permissions/${invite.id}`);
    setRefreshKey((prevKey) => prevKey + 1);
  }

  function labels(invite) {
    return [
      {
        value: invite.email,
      },
      {
        value: invite.status,
        classes:
          invite.status === "Pending" ? "text-yellow-600" : "text-green-600",
      },
    ];
  }

  function actions(invite) {
    if (invite.status === "Pending") {
      return [
        {
          element: (
            <Link onClick={() => removeInvite(invite)}>
              <FormattedMessage id="Cancel" />
            </Link>
          ),
          textClasses: "text-red-600 hover:text-red-900",
        },
      ];
    } else {
      return [];
    }
  }

  const renderForm = () => {
    return (
      <>
        <form className="mb-8">
          <div className="space-y-10 sm:space-y-12">
            <FormTitle
              titleKey="category.share"
              titleValues={{ categoryName }}
            />

            <div className="mt-10 space-y-8 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-300 sm:pb-0">
              <TextInput
                labelTextKey="E-mail"
                id="email"
                validationError={validationError}
                value={email}
                disabled={blockInviteButton}
                handleOnChange={(event) => {
                  setValidationError(null);
                  setEmail(event.target.value);
                }}
              />
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              className="rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={handleInviteClick}
              disabled={blockInviteButton}
            >
              <FormattedMessage id="Share" />
            </button>
          </div>
        </form>
        <List
          title={intl.formatMessage({ id: "Invites" })}
          emptyListTitle={intl.formatMessage({
            id: "empty.list.invites.title",
          })}
          emptyListDescription={intl.formatMessage({
            id: "empty.list.invites.description",
          })}
          fetchItemsMethod={handleFetchItems}
          actions={actions}
          labels={labels}
          refreshKey={refreshKey}
          showAddButton={false}
        />
      </>
    );
  };

  return (
    <HalfWidthPageWrapper>
      {loading && <Loading />}
      {!loading && renderForm()}
    </HalfWidthPageWrapper>
  );
}
