import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import GenerateMealPage from "./pages/generate/GenerateMealPage";
import NotFoundPage from "./pages/not-found/NotFoundPage";
import SubscribePage from "./pages/subscribe/SubscribePage";
import ViewSettingsPage from "./pages/settings/ViewSettingsPage";
import EditEmailPage from "./pages/settings/EditEmailPage";
import EditPasswordPage from "./pages/settings/EditPasswordPage";
import LoginPage from "./pages/login/LoginPage";
import ListMealsPage from "./pages/meals/ListMealsPage";
import AddMealPage from "./pages/meals/AddMealPage";
import EditMealPage from "./pages/meals/EditMealPage";
import ViewMealPage from "./pages/meals/ViewMealPage";
import ListCategoriesPage from "./pages/categories/ListCategoriesPage";
import AddCategoryPage from "./pages/categories/AddCategoryPage";
import EditCategoryPage from "./pages/categories/EditCategoryPage";
import ShareCategoryPage from "./pages/categories/ShareCategoryPage";
import ViewCategoryPage from "./pages/categories/ViewCategoryPage";
import ListNotificationsPage from "./pages/notifications/ListNotificationsPage";
import AddNotificationPage from "./pages/notifications/AddNotificationPage";
import EditNotificationPage from "./pages/notifications/EditNotificationPage";
import ViewNotificationPage from "./pages/notifications/ViewNotificationPage";
import RequireAuthWrapper from "./wrappers/auth/RequireAuthWrapper";
import UsagePage from "./pages/usage/UsagePage";
import FAQPage from "./pages/faq/FAQPage";
import TermsOfServicePage from "./pages/tos/TermsOfServicePage";
import PrivacyPolicyPage from "./pages/privacy/PrivacyPolicyPage";
import ReferralsPage from "./pages/referrals/ReferralsPage";
import SuccessPage from "./pages/success/SuccessPage";
import ListInvitesPage from "./pages/invites/ListInvitesPage";

export default function YumAlarmRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route
        path="generate"
        element={
          <RequireAuthWrapper>
            <GenerateMealPage />
          </RequireAuthWrapper>
        }
      />
      <Route
        path="meals"
        element={
          <RequireAuthWrapper>
            <ListMealsPage />
          </RequireAuthWrapper>
        }
      />
      <Route
        path="meals/add"
        element={
          <RequireAuthWrapper>
            <AddMealPage />
          </RequireAuthWrapper>
        }
      />
      <Route
        path="meals/view/:mealId"
        element={
          <RequireAuthWrapper>
            <ViewMealPage />
          </RequireAuthWrapper>
        }
      />
      <Route
        path="meals/edit/:mealId"
        element={
          <RequireAuthWrapper>
            <EditMealPage />
          </RequireAuthWrapper>
        }
      />
      <Route
        path="categories"
        element={
          <RequireAuthWrapper>
            <ListCategoriesPage />
          </RequireAuthWrapper>
        }
      />
      <Route
        path="categories/add"
        element={
          <RequireAuthWrapper>
            <AddCategoryPage />
          </RequireAuthWrapper>
        }
      />
      <Route
        path="categories/view/:categoryId"
        element={
          <RequireAuthWrapper>
            <ViewCategoryPage />
          </RequireAuthWrapper>
        }
      />
      <Route
        path="categories/edit/:categoryId"
        element={
          <RequireAuthWrapper>
            <EditCategoryPage />
          </RequireAuthWrapper>
        }
      />
      <Route
        path="categories/share/:categoryId"
        element={
          <RequireAuthWrapper>
            <ShareCategoryPage />
          </RequireAuthWrapper>
        }
      />
      <Route
        path="share-invites"
        element={
          <RequireAuthWrapper>
            <ListInvitesPage />
          </RequireAuthWrapper>
        }
      />
      <Route
        path="notifications"
        element={
          <RequireAuthWrapper>
            <ListNotificationsPage />
          </RequireAuthWrapper>
        }
      />
      <Route
        path="notifications/add"
        element={
          <RequireAuthWrapper>
            <AddNotificationPage />
          </RequireAuthWrapper>
        }
      />
      <Route
        path="notifications/view/:notificationId"
        element={
          <RequireAuthWrapper>
            <ViewNotificationPage />
          </RequireAuthWrapper>
        }
      />
      <Route
        path="notifications/edit/:notificationId"
        element={
          <RequireAuthWrapper>
            <EditNotificationPage />
          </RequireAuthWrapper>
        }
      />
      <Route path="subscribe" element={<SubscribePage />} />
      <Route
        path="settings"
        element={
          <RequireAuthWrapper>
            <ViewSettingsPage />
          </RequireAuthWrapper>
        }
      />
      <Route
        path="settings/edit/email"
        element={
          <RequireAuthWrapper>
            <EditEmailPage />
          </RequireAuthWrapper>
        }
      />
      <Route
        path="settings/edit/password"
        element={
          <RequireAuthWrapper>
            <EditPasswordPage />
          </RequireAuthWrapper>
        }
      />
      <Route
        path="usage"
        element={
          <RequireAuthWrapper>
            <UsagePage />
          </RequireAuthWrapper>
        }
      />
      <Route
        path="referrals"
        element={
          <RequireAuthWrapper>
            <ReferralsPage />
          </RequireAuthWrapper>
        }
      />
      <Route path="login" element={<LoginPage />} />
      <Route path="frequently-asked-questions" element={<FAQPage />} />
      <Route path="terms-of-service" element={<TermsOfServicePage />} />
      <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
      <Route
        path="signup-success"
        element={<SuccessPage detailMessageKey="success.signup" />}
      />
      <Route
        path="update-success"
        element={<SuccessPage detailMessageKey="success.update" />}
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
