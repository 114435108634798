import { useIntl } from "react-intl";
import { useContext } from "react";
import { LanguageContext } from "../../wrappers/language/LanguageWrapper";

export default function LanguageDropdown() {
  const { locale, selectLanguage } = useContext(LanguageContext);
  const intl = useIntl();

  const handleLanguageSelected = (event) => {
    selectLanguage(event.target.value);
  };

  return (
    <div>
      <select
        id="language"
        name="language"
        className="block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
        value={locale}
        placeholder={intl.formatMessage({ id: "language.select" })}
        onChange={handleLanguageSelected}
      >
        <option value="en">
          {intl.formatMessage({ id: "language.selector.english" })}
        </option>
        <option value="sl">
          {intl.formatMessage({ id: "language.selector.slovenian" })}
        </option>
        <option value="de">
          {intl.formatMessage({ id: "language.selector.german" })}
        </option>
      </select>
    </div>
  );
}
